<template>
  <div class="data-box">
    <div class="data-box-body">
      <div class="body-title">
        <div class="title-active">{{ activeInfo.actName }}</div>
        <div class="title-company">{{ activeInfo.companyName }}</div>
      </div>
      <div class="body-info">
        <div class="body-info-item" v-for="(item,index) in keyValueList" :key="index">
          <span class="span-label">{{ item.key }}</span>
          <span class="span-value" :style="{width: item.hasIcon ? '60%' : '',}">{{ item.value ? item.value : "无" }}</span>
          <a :href="'tel:'+ item.phone" class="iconfont icon-dianhua1 span-icon" v-show="item.hasIcon&&item.phone"></a>
        </div>
      </div>
      <div class="body-check">
        <div class="check-item" :style="{width: activeInfo.relateType === 0 ? '100%' : '48%'}" @click="showCheckInfo" v-if="activeInfo.relateType === 0 || activeInfo.relateType === 2">
          <div class="check-item-top">
            <span class="top-title">人员对接</span>
            <span class="top-show">查看</span>
          </div>
          <van-circle v-model="personRate" :rate="currentPersonRate" :speed="80" :text="circlePersonText" layer-color="#E8F0F9" :color="gradientColor" stroke-width="200" v-if="activeInfo.relateType === 2"/>
          <div class="body-progress" v-if="activeInfo.relateType === 0">
            <van-progress :percentage="currentPersonRate" color="linear-gradient(to right, #B2CBFF, #3476FF)" track-color="#E8F0F9" :show-pivot="false"></van-progress>
            <div class="progress-text" :style="{width: currentPersonRate ? currentPersonRate + '%' : '100%'}">
              <div class="span">{{currentPersonRate + '%'}}</div>
            </div>
          </div>
        </div>
        <div class="check-item" :style="{width: activeInfo.relateType === 1 ? '100%' : '48%'}" @click="showCheckInfo" v-if="activeInfo.relateType === 1 || activeInfo.relateType === 2">
          <div class="check-item-top">
            <span class="top-title">车辆对接</span>
            <span class="top-show">查看</span>
          </div>
          <van-circle v-model="carRate" :rate="currentCarRate" :speed="80" :text="circleCarText" layer-color="#E8F0F9" :color="gradientColor" stroke-width="200" v-if="activeInfo.relateType === 2"/>
          <div class="body-progress" v-if="activeInfo.relateType === 1">
            <van-progress :percentage="currentCarRate" color="linear-gradient(to right, #B2CBFF, #3476FF)" track-color="#E8F0F9" :show-pivot="false"></van-progress>
            <div class="progress-text" :style="{width: currentCarRate ? currentCarRate + '%' : '100%'}">
              <div class="span">{{currentCarRate + '%'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="body-active">
        <div class="active-title">活动信息</div>
        <div class="active-div">
          <div class="active-item" v-for="(item,index) in itemList" :key="index" @click="showActiveInfo(item)">
            <van-image class="item-image" :src="item.image"/>
            <div class="item-text">{{item.text}}</div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="showCheckPopup" round position="bottom">
      <div class="popup-body">
        <div class="check-title">
          我的对接人
        </div>
        <div class="check-info">
          <div class="check-info-left">{{ myHeadInfo.title }}</div>
          <div class="check-info-right">
            <span>{{ myHeadInfo.name ? myHeadInfo.name : "无" }}</span>
            <a :href="'tel:' + myHeadInfo.phone" class="iconfont icon-dianhua1"></a>
          </div>
        </div>
        <div class="check-line"></div>
        <div class="check-title">
          其他负责人
        </div>
        <div class="check-info" v-for="(item,index) in headInfoList" :key="index">
          <div class="check-info-left">{{ item.title }}</div>
          <div class="check-info-right">
            <span>{{ item.name ? item.name : "无" }}</span>
            <a :href="'tel:' + item.phone" class="iconfont icon-dianhua1" v-show="item.phone"></a>
          </div>
        </div>
      </div>
    </van-popup>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  activityDetail
} from "@/api/index";

export default {
  name: "parIndex",
  data() {
    return {
      activeInfo: {
        actName: "",
        companyName: ""
      },
      currentPersonRate: 0,
      currentCarRate: 0,
      personRate: 0,
      carRate: 0,
      gradientColor: {
        '0%': '#B2CBFF',
        '100%': '#3476FF'
      },
      keyValueList: [
        { key: "活动地点", value: "", hasIcon: false},
        { key: "助理人员", value: "", phone: "", hasIcon: true},
        { key: "签到日期", value: "", hasIcon: false},
        { key: "签退日期", value: "", hasIcon: false}
      ],
      itemList: [
        { image: require("../../assets/image/index_check.svg"), text: "我的对接人" },
        { image: require("../../assets/image/index_live.svg"), text: "住宿安排", router: "parLive" },
        { image: require("../../assets/image/index_material.svg"), text: "物资安排", router: "parMaterial" },
        { image: require("../../assets/image/index_meals.svg"), text: "用餐安排", router: "parMeals" },
        { image: require("../../assets/image/index_consump.svg"), text: "车辆能耗", router: "parConsumption" },
        { image: require("../../assets/image/index_clear.svg"), text: "车辆清洁", router: "parClear" }
      ],
      showCheckPopup: false,
      myHeadInfo: { relateType: 0, title: "您的对接人", name: "", phone: "" },
      headInfoList: [
        { relateType: 15, title: "活动总负责人", name: "", phone: "" },
        { relateType: 1, title: "车辆对接负责人", name: "", phone: "" },
        { relateType: 2, title: "住宿安排负责人", name: "", phone: "" },
        { relateType: 3, title: "用餐安排负责人", name: "", phone: "" },
        { relateType: 4, title: "物品发放负责人", name: "", phone: "" }
      ]
    }
  },
  computed: {
    circlePersonText() {
      return this.currentPersonRate.toFixed(0) + '%';
    },
    circleCarText() {
      return this.currentCarRate.toFixed(0) + '%';
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.$tool.getActObjInfo(that.$store.getters.getOPDetails.id,(data) => {
        that.activeInfo = data;
        that.currentPersonRate = that.activeInfo.status === 2 ? 100 : (that.activeInfo.status === 1 ? 50 : 0);
        that.currentCarRate = that.activeInfo.status === 2 ? 100 : (that.activeInfo.status === 1 ? 50 : 0);
        that.activeInfo.actName = that.activeInfo.numName;
        that.activeInfo.companyName = that.activeInfo.postName;
        that.keyValueList[0].value = that.activeInfo.actOther.areaCarUnit;
        that.keyValueList[2].value = that.activeInfo.takeStartDate;
        that.keyValueList[3].value = that.activeInfo.takeEndDate;
        that.activeInfo.activitySets.forEach(item => {
          if(item.relateType === 8){
            that.keyValueList[1].value = item.chargeName;
            that.keyValueList[1].phone = item.chargePhone;
          }
          if(item.relateType === 0){
            that.myHeadInfo.name = item.chargeName;
            that.myHeadInfo.phone = item.chargePhone;
          }
          that.headInfoList.forEach(head => {
            if(item.relateType === head.relateType){
              head.name = item.chargeName;
              head.phone = item.chargePhone;
            }
          })
        })
      })
    },
    //前往人员、车辆对接页面查看对接信息
    showCheckInfo(){
      let that = this;
      that.$router.push('parCheck');
    },
    //前往或查看活动信息 0：我的对接人，1：住宿安排，2：物资安排，3：用餐安排，4：车辆能耗，5：车辆清洁
    showActiveInfo(item){
      let that = this;
      if(Object.prototype.hasOwnProperty.call(item, "router")){
        that.$router.push(item.router);
      }else{
        that.showCheckPopup = true;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .data-box{
    width: 100%;
    height: 100%;
    .data-box-body{
      width: 100%;
      height: 100%;
      background-image:url('../../assets/image/par_index_bg.png');
      background-repeat:no-repeat;
      background-size:100% 234px;
      background-position:center top;
      .body-title{
        width: 90%;
        margin: 0px auto;
        padding: 20px 0px;
        text-align: center;
        .title-active{
          color: #FFFFFF;
          font-size: 20px;
        }
        .title-company{
          margin-top: 10px;
          color: #FFFFFF;
          font-size: 16px;
        }
      }
      .body-info{
        width: 90%;
        margin: 10px auto;
        padding: 10px 0px;
        background-color: #FFFFFF;
        border-radius: 20px;
        .body-info-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 10px 16px;
          .span-label {
            width: 30%;
            color: #8B8FAD;
            font-size: 14px;
          }
          .span-value {
            width: 70%;
            color: #3E4576;
            font-size: 14px;
          }
          .span-icon{
            width: 10%;
            text-align: right;
            color: #3476FF;
            font-size: 24px;
          }
        }
        .info-left{
          width: 80%;
          .info-item{
            padding: 0px 16px;
            height: 40px;
            line-height: 40px;
            font-size: 14px;
            .info-item-label{
              color: #8B8FAD;
              margin-right: 20px;
            }
            .info-item-value{
              color: #3E4576;
            }
          }
        }
        .info-right{
          width: 20%;
          margin-top: 38px;
          .info-icon{
            color: #3476FF;
            font-size: 20px;
          }
        }
      }
      .body-check{
        width: 90%;
        margin: 20px auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .check-item{
          width: 48%;
          border-radius: 20px;
          background-color: #FFFFFF;
          text-align: center;
          padding-bottom: 10px;
          .check-item-top{
            width: 90%;
            margin: 0px auto;
            padding: 10px 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .top-title{
              color: #3E4576;
              font-weight: bold;
              font-size: 16px;
            }
            .top-show{
              color: #8B8FAD;
              font-size: 12px;
            }
          }
        }
      }
      .body-active{
        width: 90%;
        margin: 20px auto;
        padding: 10px 0px;
        background-color: #FFFFFF;
        border-radius: 20px;
        .active-title{
          padding: 10px 20px;
          color: #3E4576;
          font-weight: bold;
          font-size: 16px;
        }
        .active-div{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;
          .active-item{
            width: 30%;
            padding: 10px 0px;
            text-align: center;
            .item-image{
              width: 50px;
              height: 50px;
            }
            .item-text{
              text-align: center;
              color: #3E4576;
              font-size: 12px;
            }
          }
        }
      }
      .body-progress{
        width: 90%;
        margin: 10px auto;
        border-radius: 16px;
        ::v-deep .van-progress {
          position: relative;
          height: 20px;
          background: #ebedf0;
          border-radius: 16px;
          .van-progress__portion {
            position: absolute;
            left: 0;
            height: 100%;
            background: #1989fa;
            border-radius: inherit;
          }
        }
        .progress-text {
          position: relative;
          top: -20px;
          right: 0;
          height: 20px;
          .span{
            font-size: 14px;
            color: white;
            padding: 2px 4px;
            text-align: right;
          }
        }
      }
    }
    .popup-body{
      width: 100%;
      padding: 20px 0px;
      .check-title{
        width: 100%;
        text-align: center;
        color: #3E4576;
        font-size: 16px;
      }
      .check-info{
        width: 80%;
        margin: 10px auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .check-info-left{
          width: 45%;
          text-align: left;
          color: #3E4576;
          font-size: 14px;
        }
        .check-info-right{
          width: 55%;
          text-align: right;
          span{
            color: #3E4576;
            font-size: 14px;
          }
          a{
            margin-left: 16px;
            font-size: 20px;
            color: #3476FF;
          }
        }
      }
      .check-line{
        width: 80%;
        margin: 20px auto;
        height: 1px;
        border-top: 1px solid gainsboro;
      }
    }
  }
</style>